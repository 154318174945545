<style lang="less">
@import "./login.less";
</style>

<template>
  <div class="login" @keydown.enter="handleSubmit">
    <div class="login-con">
      <Card :bordered="false">
        <p slot="title"><Icon type="md-log-in"></Icon>欢迎登录</p>
        <div class="form-con">
          <Form ref="usernameLoginForm" :model="form" :rules="rules">
            <FormItem prop="username">
              <i-input
                v-model="form.username"
                placeholder="请输入用户名"
                autocomplete="off"
              >
                <span slot="prepend">
                  <Icon :size="16" type="md-person"></Icon>
                </span>
              </i-input>
            </FormItem>
            <FormItem prop="password">
              <i-input
                type="password"
                v-model="form.password"
                placeholder="请输入密码"
                autocomplete="off"
              >
                <span slot="prepend">
                  <Icon :size="16" type="md-lock"></Icon>
                </span>
              </i-input>
            </FormItem>
            <FormItem>
              <div class="security-code-wrapper" style="width:260px">
                <i-input
                  v-model="form.imgCode"
                  placeholder="请输入验证码"
                  @on-change="$emit('update:value', form.imgCode)"
                />
                <img
                  :src="captchaImg"
                  alt="验证码图片加载错误"
                  title="点击刷新验证码"
                  @click="getCaptchaImg"
                />
              </div>
            </FormItem>
          </Form>
          <Button
            @click="submitLogin"
            type="primary"
            long
            :loading="isLogining"
          >
            {{ loginBtnText }}
          </Button>
          <div style="margin-top: 5px; font-size: 0.625rem">
            <Checkbox
              style="marign-top: 30px"
              v-model="rememberme"
              @on-change="setRememberMe"
              >记住我</Checkbox
            >
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import {
  initCaptcha,
  drawCodeImage,
  login,
  getOtherSet,
  userInfo,
} from "@/api/index";
import util from "@/libs/util.js";
export default {
  data() {
    return {
      captchaId: "",
      captchaImg: "",
      isLogining: false,
      loginBtnText: "登录",
      rememberme: true,
      form: {
        userName: "",
        password: "",
        imgCode: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {},
  methods: {
    getCaptchaImg() {
      // this.loadingCaptcha = true;
      initCaptcha().then((res) => {
        // this.loadingCaptcha = false;
        if (res.success) {
          this.captchaId = res.result;
          this.captchaImg = drawCodeImage + this.captchaId;
        }
      });
    },

    setRememberMe(value) {
      if (value) {
        localStorage["rememberme"] = true;
      } else {
        localStorage["rememberme"] = false;
      }
    },
    submitLogin() {
      this.$refs.usernameLoginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          login({
            username: this.form.username,
            password: this.form.password,
            code: this.form.imgCode,
            captchaId: this.captchaId,
            saveLogin: this.saveLogin
          }).then(res => {
            if (res.success) {
              this.afterLogin(res);
            } else {
              this.loading = false;
              this.getCaptchaImg();
            }
          });
        }
      });
      // // this.$refs.usernameLoginForm.validate(valid => {
      // // if (valid) {
      // //   this.loading = true;
      // sysLogin({
      //   username: this.form.username,
      //   password: this.form.password,
      //   code: this.form.imgCode,
      //   captchaId: this.captchaId,
      //   saveLogin: this.rememberme,
      // }).then((res) => {
      //   if (res.success) {
      //     this.afterLogin(res);
      //   } else {
      //     // this.loading = false;
      //     this.getCaptchaImg();
      //   }
      // });
      // // }
      // // });
    },

    afterLogin(res) {
      let accessToken = res.result;
      this.setStore("accessToken", accessToken);
      getOtherSet().then((res) => {
        if (res.result) {
          let domain = res.result.ssoDomain;
          Cookies.set("accessToken", accessToken, {
            domain: domain,
            expires: 7,
          });
        }
      });
      // 获取用户信息
      userInfo().then((res) => {
        if (res.success) {
          // 避免超过大小限制
          delete res.result.permissions;
          let roles = [];
          res.result.roles.forEach((e) => {
            roles.push(e.name);
          });
          this.setStore("roles", roles);
          this.setStore("saveLogin", this.rememberme);
          if (this.rememberme) {
            // 保存7天
            Cookies.set("userInfo", JSON.stringify(res.result), {
              expires: 7,
            });
          } else {
            Cookies.set("userInfo", JSON.stringify(res.result));
          }
          this.setStore("userInfo", res.result);
          this.$store.commit("setAvatarPath", res.result.avatar);
          // 加载菜单
          util.initRouter(this);
          this.$router.push({
            name: "home_index",
          });
        } else {
          this.loading = false;
        }
      });
    },

    handleSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.isShowModal = true;
          this.isLogining = true;
          let msg = this.$Message.loading({
            content: "正在登录中",
            duration: 0,
          });
          var pswd = this.$util.encryptPassword
            ? this.$util.encryptPassword(this.form.password)
            : this.form.password;
          const [url, data, config] = [
            "/api/system/login",
            {
              username: this.form.userName,
              password: pswd,
              rememberme: localStorage["rememberme"],
            },
            {
              headers: {
                biyiCaptchaKey: this.biyiCaptchaKey,
                biyiCaptcha: JSON.stringify({
                  code: this.value,
                }),
              },
            },
          ];
          this.$http
            .post(url, data, config)
            .then((response) => {
              msg();
              if (
                this.$route.meta.validatePaswd &&
                response.data.passwordStatus
              ) {
                this.isLogining = false;
                this.$Message.warning({
                  content:
                    response.data.passwordStatus === 1
                      ? "初始密码强度较弱，请修改后再重新登录！"
                      : "密码已使用3个月，请修改后再重新登录！",
                });
                this.$router.push({ name: "update-password" });
              } else {
                localStorage.token = response.data.token;
                this.$Message.success({
                  content: "登录成功！",
                  onClose: () => {
                    this.$router.push("/home");
                  },
                });
              }
            })
            .catch((error) => {
              msg();
              this.$refs.securityCode.init();
              this.isLogining = false;
              if (error.response) {
                switch (error.response.status) {
                  case 401:
                    if (error.response.data.attempts) {
                      this.$Message.error(
                        `登录失败，您还有 ${error.response.data.attempts} 尝试机会！`
                      );
                    } else {
                      const locktime = this.formatLockedTime(
                        error.response.data.lockoutTime
                      );
                      this.$Message.error({
                        content: `登录失败，您的账号已被锁定，请在<span style="font-weight: bold; color: #2d8cf0;">${locktime}</span>后重试！`,
                      });
                    }
                    break;
                  default:
                    this.$Message.error(
                      `登录失败，${error.response.data.message}！`
                    );
                    break;
                }
              }
            });
        }
      });
    },
    formatLockedTime(time) {
      let str = "";
      const d = parseInt(time / (3600 * 24));
      let h = parseInt((time % (3600 * 24)) / 3600);
      let m = parseInt((time % 3600) / 60);
      let s = (time % 3600) % 60;
      if (d > 0) {
        str = `${str}${d}天`;
      }
      if (h > 0) {
        str = `${str}${h}小时`;
      }
      if (m > 0) {
        str = `${str}${m}分`;
      }
      if (s > 0) {
        str = `${str}${s}秒`;
      }
      return str;
    },
  },
  mounted() {
    // this.showNotice();
    // this.relatedLogin();
    this.getCaptchaImg();
  },
  //   created() {
  //   this.$store.commit('user/logout')
  //   if (!localStorage['rememberme']) {
  //     localStorage['rememberme'] = 0
  //   }
  //   this.rememberme = !(localStorage['rememberme'] === '0')

  //   this.getCaptchaImg()
  // }
};
</script>

<style lang="less">

.security-code-wrapper {
  display: flex;
  align-items: center;
}
.security-code-wrapper > img {
  margin-left: 20px;
  height: 34px;
  cursor: pointer;
}

.replacement {
  position: absolute;
  opacity: 0;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
